import { useState } from 'react'
import { UseControllerProps, useForm } from 'react-hook-form'
import {
  AutoTableVirtualized,
  AutoTableVirtualizedColumn,
  AutoTableVirtualizedProps,
  Box,
  CircularProgress,
} from '@stationkim/front-ui'
import { Actions } from './components/actions'
import { EditableCell } from './components/editableCell'
import { EFieldMasks } from './shared'
import { AutoTableVirtualizedContainer } from './styles'

export interface FieldTableVirtualizedColumns extends AutoTableVirtualizedColumn {
  isEditable?: boolean
  mask?: EFieldMasks
  dependents?: Array<string> | string
  dataKey: string
  rules?: UseControllerProps['rules']
}

type BaseRow = Record<string, any>
type FieldTableVirtualizedRow = {
  errors?: { [key: string]: string }
  isLoading?: boolean
}

type TCellManipulationFunction = ({
  columnIndex,
  rowIndex,
  row,
}: {
  columnIndex: number
  rowIndex: number
  row: { [key: string]: any }
}) => void

export interface FieldTableVirtualizedProps extends Omit<AutoTableVirtualizedProps, 'isLoading'> {
  columns: Array<FieldTableVirtualizedColumns>
  rows: Array<BaseRow & FieldTableVirtualizedRow>
  onRowDelete?: TCellManipulationFunction
  onRowEdit?: TCellManipulationFunction
  isLoading?: boolean | number
  actionColumnTitle?: string
  frozenActionColumn?: 'right' | 'left' | undefined
}

const applyCellRenderer = ({ cellRenderer, columns }: any) => {
  return columns.map((column: any) => {
    if (column.cellRenderer) return column
    return {
      ...column,
      cellRenderer,
    }
  })
}

export const FieldTableVirtualized = ({
  columns,
  rows,
  onRowEdit,
  onRowDelete,
  isLoading = false,
  actionColumnTitle = '',
  frozenActionColumn,
  ...props
}: FieldTableVirtualizedProps) => {
  const hasActions = onRowDelete || onRowEdit

  const [rowUnderEdition, setRowUnderEdition] = useState<number | null>(null)
  const [rowUnderDeletion, setRowUnderDeletion] = useState<number | null>(null)
  const isManipulating = rowUnderDeletion !== null || rowUnderEdition !== null

  const {
    control,
    reset,
    handleSubmit,
    formState: { isDirty },
    setValue,
  } = useForm<any>({
    defaultValues: rowUnderEdition ? rows[rowUnderEdition] : ({} as any),
    shouldUnregister: false,
    mode: 'onChange',
  })

  const cellRenderer = ({ rowIndex, cellData, columns, columnIndex }: any) => {
    const isEditing = rowUnderEdition === rowIndex
    return (
      <EditableCell
        columnIndex={columnIndex}
        columns={columns}
        content={cellData}
        isEditing={isEditing}
        rowIndex={rowIndex}
        rows={rows}
        control={control}
        setValue={setValue}
      />
    )
  }

  const columnsWithRender = applyCellRenderer({
    columns,
    cellRenderer,
  })
  const columnsPlusAction: FieldTableVirtualizedProps['columns'] = hasActions
    ? [
        ...columnsWithRender,
        {
          dataKey: 'actions',
          title: actionColumnTitle,
          key: 'actions',
          resizable: false,
          width: 132,
          frozen: frozenActionColumn,
          cellRenderer: ({ rowIndex, rowData: row, columnIndex }: any) => {
            const isEditing = rowUnderEdition === rowIndex
            const isDeleting = rowUnderDeletion === rowIndex
            const isLastColumn = columnIndex === columnsPlusAction.length - 1
            if (isLoading === rowIndex && isLastColumn)
              return (
                <Box
                  sx={{
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    paddingRight: '16px',
                    justifyContent: 'end',
                  }}
                >
                  <CircularProgress size='24px' />
                </Box>
              )
            return (
              <Actions
                isLoading={isLoading !== false}
                isEditable={!isManipulating && Boolean(onRowEdit)}
                isDeletable={!isManipulating && Boolean(onRowDelete)}
                isEditing={isEditing}
                isDeleting={isDeleting}
                handlers={{
                  handleDelete: () => setRowUnderDeletion(rowIndex),
                  handleDeleteCancel: () => setRowUnderDeletion(null),
                  handleDeleteConfirm: () => {
                    if (onRowDelete) onRowDelete({ columnIndex, row, rowIndex })
                    setRowUnderDeletion(null)
                  },
                  handleEdit: () => {
                    setRowUnderEdition(rowIndex)
                    reset(rows[rowIndex])
                  },
                  handleEditionCancel: () => setRowUnderEdition(null),
                  handleEditionConfirm: () => null,
                }}
              />
            )
          },
        },
      ]
    : columns

  const onSubmit = (data: any) => {
    if (onRowEdit && isDirty) onRowEdit({ row: data, columnIndex: 0, rowIndex: 0 })
    setRowUnderEdition(null)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <AutoTableVirtualizedContainer hoverable={!isManipulating}>
        <AutoTableVirtualized
          columns={columnsPlusAction}
          rows={rows}
          rowProps={({ rowIndex }: { rowIndex: number }) => ({
            dataIsEditing: String(rowUnderEdition === rowIndex),
            dataIsDeleting: String(rowUnderDeletion === rowIndex),
          })}
          isLoading={typeof isLoading === 'boolean' ? isLoading : false}
          {...props}
          ignoreFunctionInColumnCompare={false}
        />
      </AutoTableVirtualizedContainer>
    </form>
  )
}
