import * as yup from 'yup'
import { validateCNPJ } from 'validations-br'

const compositeNameRegex = /^[a-zA-ZÀ-ÿ]+(\s+(de|e|da|do)?\s*[a-zA-ZÀ-ÿ]+)+$/

const schema = yup.object().shape({
  abortEarly: false,
  corporateName: yup
    .string()
    .max(90, 'Campo limitado a 90 caracteres')
    .test('no-trailing-space', 'Remova o espaço no final', (value) => value && !value.endsWith(' '))
    .matches(compositeNameRegex, {
      message: 'A razão social deve ser composto',
      excludeEmptyString: true,
    })
    .required('Razão social é um campo requerido')
    .test('no-trailing-space', 'Remova o espaço no final', (value) => value && !value.endsWith(' ')),
  tradeName: yup
    .string()
    .max(40, 'Campo limitado a 40 caracteres')
    .required('Nome Fantasia é um campo requerido')
    .test('no-trailing-space', 'Remova o espaço no final', (value) => value && !value.endsWith(' '))
    .matches(compositeNameRegex, {
      message: 'Nome Fantasia deve ser composto',
      excludeEmptyString: true,
    }),

  cnpj: yup.string().when('$register', {
    is: true,
    then: yup
      .string()
      .required('CNPJ é um campo requerido')
      .test('is-cnpj', 'CNPJ invalido', (value) => validateCNPJ(value)),
  }),
  stateRegistration: yup
    .string()
    .min(9, 'Insira a inscrição estadual completa')
    .max(14, 'Campo limitado a 14 caracteres')
    .test('only-letters-digits', 'Apenas letras e números são aceitos', (value) => !value.match(/[^0-9|a-z]/gi))
    .required('Inscrição estadual é um campo requerido'),
  municipalRegistration: yup
    .string()
    .max(11, 'Campo limitado a 11 caracteres')
    .test(
      'only-letters-digits',
      'Apenas números ou a palavra isento são aceitos',
      (value) => !value.match(/[^0-9]/) || value.toLowerCase() === 'isento',
    )
    .required('Inscrição municipal é um campo requerido'),
  numberBeneficiaries: yup.string().required('Número de Beneficiários é um campo requerido'),
})

export default schema
