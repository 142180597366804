import { IOrder } from 'main/interfaces'
import moment from 'moment'
import { ORDER_PAYMENT_TYPE } from 'main/utils/constants'

export const organizeData = ({ data }: { data: any }): IOrder | null => {
  if (!data) return null
  const rawOrder = data.data
  const organizedData: IOrder = {
    paymentDate: rawOrder.payments?.[0]?.liquidationDate
      ? moment(rawOrder.payments?.[0]?.liquidationDate).format('DD/MM/YYYY')
      : null,
    isFinancedSales: rawOrder?.isFinancedSales,
    status: rawOrder?.status,
    dueDate: rawOrder?.dueDate,
    orderId: rawOrder.id,
    orderCode: rawOrder.code,
    orderType: rawOrder.orderType,
    orderStatus: rawOrder.status,
    orderOperatorName: rawOrder.operatorName,
    orderDate: moment(rawOrder.orderDate).format('DD/MM/YYYY HH:mm'),
    totalItems: rawOrder.totalItens,
    usedPaymentsTypes:
      rawOrder.payments.length > 0
        ? rawOrder.payments.map((payment: any) => {
            const entry = Object.entries(ORDER_PAYMENT_TYPE).find((entry) => entry[1] === payment.paymentType)
            return entry ? entry[0] : undefined
          })
        : ['FREE'],
    orderSummary: {
      orderValue: rawOrder.orderSumary.orderValue,
      administrativeFee: rawOrder.orderSumary.administrativeFee,
      processingFee: rawOrder.orderSumary.processingFee,
      issFee: rawOrder.orderSumary.issFee,
      ticketingBalance: rawOrder.orderSumary.ticketingBalance,
      walletUsage: rawOrder.orderSumary.walletUsage,
      orderTotal: rawOrder.orderSumary.subTotalOrder,
      totalPaid: rawOrder.orderSumary.totalAmount,
      transferFee: rawOrder.orderSumary.transferFee,
    },
    slip: !rawOrder.slip
      ? null
      : {
          slipLink: rawOrder.slip.slipLink,
          barcodeNumber: rawOrder.slip.digitableLine,
          slipDueDate: rawOrder.slip.dueDate ? moment(rawOrder.slip.dueDate).format('DD/MM/YYYY') : '',
          value: 0,
        },
    destinationAddress: !rawOrder.destinationAddress
      ? null
      : {
          cep: rawOrder.destinationAddress.cep,
          city: rawOrder.destinationAddress.city,
          laneName: rawOrder.destinationAddress.laneName,
          number: rawOrder.destinationAddress.number,
          state: rawOrder.destinationAddress.state,
        },
    cart: !rawOrder.cart
      ? null
      : {
          cartOrders: rawOrder.cart.cartOrders.map((order: any) => ({
            code: order.orderCode,
            id: order.orderId,
            status: order.status,
            totalItems: order.totalItens,
            totalValue: order.totalValue,
            type: order.orderType,
            operatorName: order.operatorName,
          })),
          cartSummary: {
            firstCopy: rawOrder.cart.cartValuesSummary.firstCopyValue,
            rechargeValue: rawOrder.cart.cartValuesSummary.rechargeValue,
            secondCopy: rawOrder.cart.cartValuesSummary.secondCopyValue,
            administrativeFee: rawOrder.cart.cartValuesSummary.administrateFee,
            processingFee: rawOrder.cart.cartValuesSummary.processingFee,
            issFee: rawOrder.cart.cartValuesSummary.issFeeValue,
            ticketingBalance: rawOrder.cart.cartValuesSummary.ticketingBalance,
            walletUsage: rawOrder.cart.cartValuesSummary.walletValue,
            totalPaid: rawOrder.cart.cartValuesSummary.totalAmount,
            totalInOrders: rawOrder.cart.cartValuesSummary.totalInOrders,
            orderTotal: rawOrder.cart.cartValuesSummary.subTotalOrders,
            transferFee: rawOrder.cart.cartValuesSummary.transferFeeValue || 0,
          },
          id: rawOrder.cart.id,
          code: rawOrder.cart.code,
          hasRecharge: rawOrder.cart.hasRecharge,
          hasFirstCopy: rawOrder.cart.hasFirstCopy,
          hasSecondCopy: rawOrder.cart.hasSecondCopy,
        },
    cancelDate: rawOrder.cancelDate,
  }
  return organizedData
}
