import React from 'react'

import { TwoOptions } from '@/presentation/components/modalV2'
import { useNavigate } from 'react-router-dom'

interface IRecoverPassword {
  onClose: () => void
  open: boolean
}

export const RecoverPassword: React.FC<IRecoverPassword> = ({ onClose, open }) => {
  const navigate = useNavigate()

  return (
    <TwoOptions
      open={open}
      onClose={onClose}
      onLeftClick={() => navigate('/login')}
      onRightClick={() => navigate('/esqueci_minha_senha')}
    >
      E-mail já existente. Deseja recuperar a senha?
    </TwoOptions>
  )
}
