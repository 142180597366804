import React, { useState, useEffect } from 'react'
import { Container, Col } from 'react-grid-system'
import { useForm, useFieldArray } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button } from '@stationkim/front-ui'
import { Title } from 'presentation/components'
import { AddAddress, Fieldset, Hr, Row } from './style'
import { useDispatch } from 'react-redux'
import schema from '../schema/addresses'
import Modal from '../../modal'
import AddressesGrid from './addressesGrid'
import { companyAddressesClear } from '@/store/modules/empresa/actions'
import ErrorModal from '../ErrorModal'
import { FORM_MODE } from '@/utils/constants'
import useService from 'main/hooks/useService'
import { getAddressesTypesForCompanyRegister, getLaneTypesForCompanyRegister } from '@/services/companiesServices'
import { useLocation } from 'react-router-dom'

const ADDRESSES_LIMIT = 1

const Addresses = ({ changeForm, controlNavigation, mode, nextTab, prevTab, saveOnStore, companyData }) => {
  const readOnly = mode === FORM_MODE.VIEW ? true : false
  const [cancelModal, setCancelModal] = useState(false)
  const [needDeliveryAddress, setNeedDeliveryAddress] = useState(false)
  const [removeAddres, setRemoveAddres] = useState(null)
  const {
    state: { token },
  } = useLocation()

  const dispatch = useDispatch()

  let defaultValues = {}
  if (companyData != null) {
    defaultValues = {
      addresses: companyData?.addresses?.filter((address) => address.isMain === true) || [{ cep: '' }],
      deliveryAddresses: companyData?.addresses?.filter((address) => address.isMain !== true) || [],
    }
  }

  const hookForm = useForm({
    defaultValues: defaultValues,
    mode: 'onChange',
    resolver: yupResolver(schema),
    shouldUnregister: true,
  })

  const addressesFieldArray = useFieldArray({
    control: hookForm.control,
    name: 'addresses',
  })

  const deliveryAddressesFieldArray = useFieldArray({
    control: hookForm.control,
    name: 'deliveryAddresses',
  })
  const [fetchAddressTypeCodesState, fetchAddressTypeCodesRequest] = useService(getAddressesTypesForCompanyRegister, {
    silent: true,
  })
  const addressTypeCodes = fetchAddressTypeCodesState?.response?.data?.data || []

  const [fetchLaneTypesState, fetchLaneTypesRequest] = useService(getLaneTypesForCompanyRegister, { silent: true })
  const laneTypeCodes = fetchLaneTypesState?.response?.data?.data || []

  useEffect(() => {
    fetchLaneTypesRequest(token)
    fetchAddressTypeCodesRequest(token)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const canPass = hookForm.formState.isValid
    controlNavigation(canPass)
    // eslint-disable-next-line
  }, [hookForm.formState.isValid, controlNavigation])

  function generateAddressFields(field, fieldsArray) {
    return fieldsArray.fields.map((_, index) => {
      const errors = hookForm.formState.errors[field]?.length >= index - 1 && hookForm.formState.errors[field][index]
      return (
        <AddressesGrid
          errors={errors}
          form={hookForm}
          isMain={field === 'addresses'}
          name={`${field}.${index}`}
          key={_.id}
          addressTypeCodes={addressTypeCodes}
          laneTypeCodes={laneTypeCodes}
          isLoading={fetchAddressTypeCodesState.loading || fetchLaneTypesState.loading}
          remove={
            field !== 'addresses'
              ? () => {
                  setRemoveAddres(() => () => {
                    fieldsArray.remove(index)
                    setRemoveAddres(null)
                  })
                }
              : null
          }
        />
      )
    })
  }

  function confirmCancel() {
    setCancelModal(false)
    dispatch(companyAddressesClear())
    prevTab()
  }

  function checkIfHasADeliveryAddres(data) {
    return data.deliveryAddresses.length < 1 && data.addresses[0].canDeliver !== true
  }

  const onSubmit = (data) => {
    if (checkIfHasADeliveryAddres(data)) {
      setNeedDeliveryAddress(true)
      return
    }
    saveOnStore(data)
    nextTab()
  }

  changeForm(() => {
    if (checkIfHasADeliveryAddres(hookForm.getValues())) {
      controlNavigation(false)
      setNeedDeliveryAddress(true)
      return
    }
    hookForm.handleSubmit(saveOnStore)()
  })

  return (
    <>
      <form onSubmit={hookForm.handleSubmit(onSubmit)} id='addressInformation'>
        <Fieldset disabled={readOnly}>
          <Container style={{ margin: 0, padding: 0, maxWidth: 'initial' }}>
            {generateAddressFields('addresses', addressesFieldArray)}
            {addressesFieldArray.fields.length < ADDRESSES_LIMIT && (
              <Row>
                <Col sm={12}>
                  <AddAddress onClick={() => addressesFieldArray.append({ cep: '' })}>Adicionar endereço</AddAddress>
                </Col>
              </Row>
            )}
            <Row>
              <Col sm={12}>
                <Hr />
              </Col>
            </Row>
            {generateAddressFields('deliveryAddresses', deliveryAddressesFieldArray)}
            <Row>
              <Col sm={12}>
                <AddAddress onClick={() => deliveryAddressesFieldArray.append({ cep: '' })}>
                  <svg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                      d='M0.5625 8.30566C0.5625 3.89941 4.125 0.305664 8.5625 0.305664C12.9688 0.305664 16.5625 3.89941 16.5625 8.30566C16.5625 12.7432 12.9688 16.3057 8.5625 16.3057C4.125 16.3057 0.5625 12.7432 0.5625 8.30566ZM8.5625 11.8057C8.96875 11.8057 9.3125 11.4932 9.3125 11.0557V9.05566H11.3125C11.7188 9.05566 12.0625 8.74316 12.0625 8.30566C12.0625 7.89941 11.7188 7.55566 11.3125 7.55566H9.3125V5.55566C9.3125 5.14941 8.96875 4.80566 8.5625 4.80566C8.125 4.80566 7.8125 5.14941 7.8125 5.55566V7.55566H5.8125C5.375 7.55566 5.0625 7.89941 5.0625 8.30566C5.0625 8.74316 5.375 9.05566 5.8125 9.05566H7.8125V11.0557C7.8125 11.4932 8.125 11.8057 8.5625 11.8057Z'
                      fill='#1976D2'
                    />
                  </svg>
                  Adicionar endereço
                </AddAddress>
              </Col>
            </Row>
          </Container>
        </Fieldset>
      </form>
      <Row justify='end' gutterWidth={24} style={{ marginTop: '8vh' }}>
        <Col sm='content'>
          <Button variant='outlined' onClick={() => setCancelModal(true)}>
            Voltar
          </Button>
        </Col>
        <Col sm='content'>
          {mode === FORM_MODE.VIEW ? (
            <Button type='button' onClick={nextTab} variant='contained'>
              Continuar
            </Button>
          ) : (
            <Button type='submit' form='addressInformation' variant='contained'>
              Continuar
            </Button>
          )}
        </Col>
      </Row>

      {!!removeAddres && (
        <Modal
          open={!!removeAddres}
          message='Deseja mesmo apagar este endereço?'
          confirm={() => {
            removeAddres()
          }}
          cancel={() => {
            setRemoveAddres(null)
          }}
        />
      )}

      {cancelModal && (
        <Modal
          message='Atenção! Os dados preenchidos serão perdidos. Deseja sair da página?'
          open={cancelModal}
          confirm={confirmCancel}
          cancel={() => setCancelModal(false)}
        />
      )}
      {needDeliveryAddress && (
        <ErrorModal open={needDeliveryAddress} cancel={() => setNeedDeliveryAddress(false)}>
          Atenção! É necessário definir ao menos um endereço de entrega.
        </ErrorModal>
      )}
    </>
  )
}
export default Addresses
