import React, { useEffect } from 'react'
import { Breadcrumb, Content, Spacing, Title } from 'presentation/components'

import CompanyInfoResume from './companyInfoResume'
import { useLocation, useNavigate } from 'react-router-dom'

const MigrarCadastro = () => {
  const navigate = useNavigate()

  const location = useLocation()

  const cnpj = location?.state?.cnpj
  const token = location?.state?.token

  const next = location.state?.next || 0

  const operatorsData = location?.state?.operators

  const operatorsInfo = location?.state?.operatorsInfo || []
  const isFinished = next === operatorsInfo?.length

  const operators = operatorsData?.map((operator) => {
    return {
      OperatorId: operator?.operatorId,
      OperatorCardId: operator?.operatorCardId,
      IsMigration: operator?.isMigration,
    }
  })

  // useEffect(() => {
  //   if (isFinished) {
  //     navigate('/cadastro', {
  //       state: {
  //         operators,
  //         cnpj,
  //         token,
  //         mode: 'FORM',
  //         isMigration: true,
  //       },
  //     })
  //   }
  // }, [isFinished])

  return (
    <Content>
      <Title>Migrar Cadastro de Empresa</Title>
      <Spacing top='16px' bottom='16px'>
        <Breadcrumb />
      </Spacing>
      {isFinished ? (
        <CompanyInfoResume operatorsInfo={operatorsInfo} operatorInfo={operatorsInfo[next]} next={next} />
      ) : null}
    </Content>
  )
}

export default MigrarCadastro
