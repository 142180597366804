import { DatePicker, Spinner } from '@/presentation/components/atoms'
import { faX } from '@fortawesome/free-solid-svg-icons'
import { styled } from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
  Select,
  TextField,
  Typography,
} from '@stationkim/front-ui'
import { Controller, useForm } from 'react-hook-form'
import { useCreateEmployee } from '../hooks/useCreateEmployee'
import { UFs } from './utils/ufs'
import { schema } from './utils/schema'
import { useGetAddressByCep } from '@/main/hooks/useGetAddressByCep'
import { useEffect } from 'react'

interface IForm {
  nome: string
  cpf: string
  dataNascimento: string
  nomeDaMae: string
  sexo: 'f' | 'm'
  cep: string
  uf: string
  cidade: string
  endereco: string
  bairro: string
  numero: string
}

interface EmployeeRegisterDialogProps {
  onClose?: () => void
  employeeToRegister?: any //tipar de acordo com o payload
}

const RequiredIcon = styled('span')(({ theme }) => ({ color: theme.palette.error.main, marginLeft: '4px' }))

export const EmployeeRegisterDialog = ({ onClose, employeeToRegister }: EmployeeRegisterDialogProps) => {
  const { createEmployee } = useCreateEmployee({ onClose })

  const { getAddress } = useGetAddressByCep()

  const { control, handleSubmit, setValue } = useForm<IForm>({
    defaultValues: {
      ...employeeToRegister,
      sexo: String(employeeToRegister.sexo).charAt(0).toLowerCase(),
    },
    resolver: yupResolver(schema),
  })

  const updateAddressByCep = (cep: string) => {
    if (cep.length === 8) {
      getAddress.mutate(
        { cep },
        {
          onSuccess: (res) => {
            const data = res.data
            if (data) {
              setValue('uf', data.uf)
              setValue('endereco', data.logradouro)
              setValue('bairro', data.bairro)
              setValue('cidade', data.localidade)
            }
          },
        },
      )
    }
  }

  const onSubmit = (data) => {
    const dataNascimento = new Date(data?.dataNascimento).toISOString()
    createEmployee.mutate({ ...data, dataNascimento })
  }

  return (
    <Dialog open={Boolean(employeeToRegister)} onClose={onClose} maxWidth='md'>
      {createEmployee.isLoading ? (
        <Spinner />
      ) : (
        <>
          <DialogTitle>
            Cadastrar colaborador
            <Icon icon={faX} onClick={onClose} />
          </DialogTitle>
          <DialogContent sx={{ margin: '16px 0 64px 0', paddingRight: '8px' }}>
            <Typography variant='body2' sx={{ margin: '16px 0 16px 0' }}>
              Informe os dados do colaborador que você deseja cadastrar (Todos os campos são obrigatórios).
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)} id='newEmployee' noValidate>
              <Grid container spacing='16px'>
                <Grid item xs={12}>
                  <Typography variant='h4' sx={{ fontWeight: 600 }}>
                    Dados pessoais<RequiredIcon>*</RequiredIcon>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    control={control}
                    name='nome'
                    render={({ field: { onChange, ref, value }, fieldState: { error, isDirty } }) => (
                      <TextField
                        label='Nome do colaborador'
                        value={value}
                        onChange={onChange}
                        inputRef={ref}
                        error={Boolean(error || (!isDirty && employeeToRegister?.errors?.['nome']))}
                        helperText={error?.message || (!isDirty && employeeToRegister?.errors?.['nome'])}
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    control={control}
                    name='cpf'
                    render={({ field: { onChange, ref, value }, fieldState: { error, isDirty } }) => (
                      <TextField
                        label='CPF'
                        value={value}
                        onChange={onChange}
                        inputRef={ref}
                        error={Boolean(error || (!isDirty && employeeToRegister?.errors?.['cpf']))}
                        helperText={error?.message || (!isDirty && employeeToRegister?.errors?.['cpf'])}
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    control={control}
                    name='nomeDaMae'
                    render={({ field: { onChange, ref, value }, fieldState: { error, isDirty } }) => (
                      <TextField
                        label='Nome da mãe'
                        value={value}
                        onChange={onChange}
                        inputRef={ref}
                        error={Boolean(error || (!isDirty && employeeToRegister?.errors?.['nomeDaMae']))}
                        helperText={error?.message || (!isDirty && employeeToRegister?.errors?.['nomeDaMae'])}
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Controller
                    control={control}
                    name='sexo'
                    render={({ field: { onChange, ref, value }, fieldState: { error, isDirty } }) => (
                      <Select
                        variant='outlined'
                        options={[
                          { label: 'Masculino', value: 'm' },
                          { label: 'Feminino', value: 'f' },
                        ]}
                        label='Sexo'
                        value={value}
                        onChange={onChange}
                        inputRef={ref}
                        error={Boolean(error || (!isDirty && employeeToRegister?.errors?.['sexo']))}
                        helperText={error?.message || (!isDirty && employeeToRegister?.errors?.['sexo'])}
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Controller
                    control={control}
                    name='dataNascimento'
                    render={({ field: { onChange, ref, value }, fieldState: { error, isDirty } }) => {
                      return (
                        <>
                          <label style={{ margin: '4px 0 8px 0', display: 'block', whiteSpace: 'nowrap' }}>
                            Data de nascimento
                          </label>
                          <DatePicker
                            value={value}
                            onChange={onChange}
                            inputRef={ref}
                            error={Boolean(error || (!isDirty && employeeToRegister?.errors?.['dataNascimento']))}
                            helperText={error?.message || (!isDirty && employeeToRegister?.errors?.['dataNascimento'])}
                            required={true}
                            sx={{ width: '100%' }}
                          />
                        </>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='h4' sx={{ fontWeight: 600 }}>
                    Endereço<RequiredIcon>*</RequiredIcon>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Controller
                    control={control}
                    name='cep'
                    render={({ field: { onChange, ref, value }, fieldState: { error, isDirty } }) => (
                      <TextField
                        label='CEP'
                        value={value}
                        onChange={(e) => {
                          updateAddressByCep(e.target.value)
                          onChange(e)
                        }}
                        inputRef={ref}
                        error={Boolean(error || (!isDirty && employeeToRegister?.errors?.['cep']))}
                        helperText={error?.message || (!isDirty && employeeToRegister?.errors?.['cep'])}
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Controller
                    control={control}
                    name='cidade'
                    render={({ field: { onChange, ref, value }, fieldState: { error, isDirty } }) => (
                      <TextField
                        label='Cidade'
                        value={value}
                        onChange={onChange}
                        inputRef={ref}
                        error={Boolean(error || (!isDirty && employeeToRegister?.errors?.['cidade']))}
                        helperText={error?.message || (!isDirty && employeeToRegister?.errors?.['cidade'])}
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Controller
                    control={control}
                    name='uf'
                    render={({ field: { onChange, ref, value }, fieldState: { error, isDirty } }) => (
                      <Select
                        variant='outlined'
                        options={UFs}
                        label='UF'
                        value={value}
                        onChange={onChange}
                        inputRef={ref}
                        error={Boolean(error || (!isDirty && employeeToRegister?.errors?.['uf']))}
                        helperText={error?.message || (!isDirty && employeeToRegister?.errors?.['uf'])}
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name='endereco'
                    render={({ field: { onChange, ref, value }, fieldState: { error, isDirty } }) => (
                      <TextField
                        label='Endereço'
                        value={value}
                        onChange={onChange}
                        inputRef={ref}
                        error={Boolean(error || (!isDirty && employeeToRegister?.errors?.['endereco']))}
                        helperText={error?.message || (!isDirty && employeeToRegister?.errors?.['endereco'])}
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    control={control}
                    name='bairro'
                    render={({ field: { onChange, ref, value }, fieldState: { error, isDirty } }) => (
                      <TextField
                        label='Bairro'
                        value={value}
                        onChange={onChange}
                        inputRef={ref}
                        error={Boolean(error || (!isDirty && employeeToRegister?.errors?.['bairro']))}
                        helperText={error?.message || (!isDirty && employeeToRegister?.errors?.['bairro'])}
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    control={control}
                    name='numero'
                    render={({ field: { onChange, ref, value }, fieldState: { error, isDirty } }) => (
                      <TextField
                        label='Número'
                        value={value}
                        onChange={onChange}
                        inputRef={ref}
                        error={Boolean(error || (!isDirty && employeeToRegister?.errors?.['numero']))}
                        helperText={error?.message || (!isDirty && employeeToRegister?.errors?.['numero'])}
                        required
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </form>
          </DialogContent>
          <DialogActions>
            <Button variant='outlined' fullWidth onClick={onClose} disabled={createEmployee.isLoading}>
              Cancelar
            </Button>
            <Button variant='contained' fullWidth type='submit' form='newEmployee' disabled={createEmployee.isLoading}>
              Confirmar
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}
