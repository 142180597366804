import { faEnvelope, faUser } from '@fortawesome/free-regular-svg-icons'
import { validateEmail } from 'validations-br'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller } from 'react-hook-form'
import { TextField } from '@/presentation/components/molecules'
import { Notification } from '@/presentation/components'
import { Button, CircularProgress, Link, Icon, Typography } from '@/presentation/components/atoms'
import { useForm } from '@/main/hooks'
import { StyledBox, StyledPaper } from './styles'
import { getResaleSiteFromUrl } from '@/utils/functionUtils'
import { IRegister, useRegister } from './hooks/useRegister'
import { schema } from './utils/schema'
import { RecoverPassword } from './modal/RecoverPassword'

const RegisterUser = () => {
  const { register, isLoading, open, setOpen } = useRegister()

  const { control, handleSubmit } = useForm({
    defaultValues: {
      email: '',
      username: '',
      confirmEmail: '',
    },
    resolver: yupResolver(schema),
  })

  const onSubmit = (data: IRegister) => {
    register(data)
  }

  return (
    <StyledBox>
      <Notification />
      <StyledPaper>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <Typography variant='h1' display='flex' alignSelf='flex-start'>
              Novo cadastro!
            </Typography>
            <Typography sx={{ marginBottom: '16px' }}>Informe o nome e o e-mail do novo usuário.</Typography>
            <Controller
              control={control}
              name='username'
              render={({ field: { value, onChange }, fieldState: { error, isDirty } }) => (
                <TextField
                  label='Nome completo'
                  placeholder='Informe o nome'
                  startAdornment={<Icon icon={faUser} />}
                  value={value}
                  helperText={error?.message}
                  onChange={onChange}
                  error={Boolean(error)}
                  success={!error && isDirty}
                />
              )}
            />
            <Controller
              control={control}
              name='email'
              render={({ field: { value, onChange }, fieldState: { error, isDirty } }) => (
                <TextField
                  label='E-mail'
                  placeholder='Informe o e-mail'
                  startAdornment={<Icon icon={faEnvelope} />}
                  value={value}
                  helperText={error?.message}
                  onChange={onChange}
                  error={Boolean(error)}
                  success={!error && isDirty}
                  autoComplete='email'
                />
              )}
            />

            <Controller
              control={control}
              name='confirmEmail'
              render={({ field: { value, onChange }, fieldState: { error, isDirty } }) => (
                <TextField
                  label='Confirmação de e-mail'
                  placeholder='Informe o e-mail'
                  startAdornment={<Icon icon={faEnvelope} />}
                  value={value}
                  helperText={error?.message}
                  onChange={onChange}
                  error={Boolean(error)}
                  success={!error && isDirty}
                  autoComplete='email'
                />
              )}
            />

            <Button sx={{ marginTop: '10px' }} variant='contained' type='submit' fullWidth>
              Confirmar
            </Button>
            <Link to={'/login'}>Voltar para tela de login</Link>
          </form>
        )}
      </StyledPaper>
      <RecoverPassword open={open} onClose={() => setOpen(false)} />
    </StyledBox>
  )
}

export default RegisterUser
