import React, { useCallback, useEffect, useState } from 'react'
import CompanyData from './companyData'
import Addresses from './addresses'
import { useDispatch, useSelector } from 'react-redux'
import {
  companyIdStore,
  companyDataStore,
  companyAddressesStore,
  companyContactsStore,
  companyDocumentsStore,
  companyDataClear,
} from '@/store/modules/empresa/actions'

import { Tabs, TabPanel, Spinner } from 'presentation/components'

import Contacts from './contacts'
import Documents from './documents'
import { BENEFIT_TYPES, FORM_MODE, NOTIFICATION_TYPE } from '@/utils/constants'
import { postCreateCompany, putUpdateCompany } from '@/services/companiesServices'
import { pushNotification } from '@/store/modules/notification/actions'
import errorHandler from '@/utils/errorHandler'
import successHandler from '@/utils/successHandler'
import { Paper } from 'presentation/components/atoms/paper'

import { CategorizedItemsSelection } from 'presentation/components/organisms/categorizedItemsSelection'
import useService from 'main/hooks/useService'
import { checkIfOperatorHasRegister, getBenefits } from '@/services/registerServices'
import { Box } from 'presentation/components/atoms/box'
import { getResaleSiteFromUrl } from '@/utils/functionUtils'
import { useLocation, useNavigate } from 'react-router-dom'
import { TabList, Tab } from './style'

const defaultCategories = [
  { name: 'Vale Alimentação', id: BENEFIT_TYPES.SNACK },
  { name: 'Vale Refeição', id: BENEFIT_TYPES.FOOD },
  { name: 'Vale Combustível', id: BENEFIT_TYPES.FUEL },
  { name: 'VT Eletrônico', id: BENEFIT_TYPES.TRANSPORT },
  { name: 'Vale Transporte Papel', id: BENEFIT_TYPES.TRANSPORT_PAPER },
]

const Form = ({ done, location }) => {
  const navigate = useNavigate()
  const {
    state: { token, cnpj, isMigration, operators },
  } = useLocation()
  const [tabIndex, setTabIndex] = useState(0)
  const [fullfieldTab, setFullfieldTab] = useState(0)
  const [operatorsCard, setOperatorsCard] = useState([])
  const [sendToApi, setSendToApi] = useState(false)
  const [loading, setLoading] = useState(false)
  const [mode] = useState(location?.state?.mode)
  const tabCount = 4
  const dispatch = useDispatch()
  const companyData = useSelector((state) => state.empresa)
  let isNavegable = false
  let formSubmit = null

  const saveApi = useCallback(
    async (payload) => {
      const { error, response } = await postCreateCompany(payload, token)
      if (response) {
        dispatch(pushNotification(successHandler('Salvo com sucesso')))
        return response
      } else dispatch(pushNotification(errorHandler(error.response)))
    },
    [dispatch],
  )

  const updateApi = useCallback(
    async (payload) => {
      const { error, response } = await putUpdateCompany(payload, token)
      if (response) {
        dispatch(pushNotification(successHandler('Salvo com sucesso')))
        return response
      } else dispatch(pushNotification(errorHandler(error.response)))
    },
    [dispatch],
  )

  const apiCall = useCallback(async () => {
    if (sendToApi) {
      setLoading(true)
      let response
      if (mode === FORM_MODE.ADD || !mode)
        response = await saveApi({
          ...companyData.data,
          OperatorsCard: isMigration
            ? operators
            : operatorsCard.map((benefit) => ({
                OperatorId: benefit.operatorId,
                OperatorCardId: benefit.operatorCardId,
                IsMigration: false,
              })),
        })
      if (mode === FORM_MODE.EDIT) response = await updateApi(companyData.data)
      if (response && (!mode || mode === FORM_MODE.ADD)) {
        dispatch(companyIdStore({ id: response.data }))
        nextTab()
      }
      setSendToApi(false)
      setLoading(false)
    }
    // eslint-disable-next-line
  }, [sendToApi])

  const [fetchBenefitsState, fetchBenefitsRequest] = useService(getBenefits, {
    onCompleted: (res) => {
      generateCategories(res.data)
      if (res.data.length === 1) {
        setOperatorsCard(res.data)
        setIsSelectingBenefits(false)
      }
    },
  })

  // eslint-disable-next-line
  useEffect(() => {
    apiCall()
  }, [sendToApi])
  // eslint-disable-next-line
  useEffect(() => {
    fetchBenefitsRequest(getResaleSiteFromUrl(), token)
  }, [])

  function changeForm(source) {
    formSubmit = source
  }

  function nextTab() {
    const newTab = tabIndex + 1 < tabCount ? tabIndex + 1 : 0
    setFullfieldTab((value) => value + 1)
    setTabIndex(newTab)
  }

  function prevTab() {
    const newTab = tabIndex - 1 >= 0 ? tabIndex - 1 : 0
    setTabIndex(newTab)
  }

  function handleTabNavigation(index) {
    formSubmit && formSubmit()
    if (!isNavegable || index > fullfieldTab + 1) return
    setFullfieldTab((value) => value + 1)
    setTabIndex(index)
  }

  function controlNavigation(isValid) {
    isNavegable = isValid
  }

  function returnToList() {
    navigate('/colaboradores')
  }

  const [categories, setCategories] = useState(defaultCategories)
  const [isSelectingBenefits, setIsSelectingBenefits] = useState(isMigration ? false : true)

  const generateCategories = (benefits) => {
    const categoriesInUse = defaultCategories.filter((category) => {
      return benefits.some((benefit) => String(benefit?.benefitType) === String(category.id))
    })
    setCategories(categoriesInUse)
  }

  const onConfirm = async () => {
    if (operatorsCard.length < 1) {
      dispatch(
        pushNotification({
          type: NOTIFICATION_TYPE.WARNING,
          content: 'Para continuar é obrigatório a seleção de pelo menos 1 benefício',
        }),
      )
      return
    }

    const newOperatorsCard = operatorsCard.map((operator) => ({
      operatorId: operator.operatorId,
      operatorCardId: operator.operatorCardId,
      status: 1,
    }))

    const {
      response: { data },
    } = await checkIfOperatorHasRegister({ cnpj, operatorsCard: newOperatorsCard, token })

    const shouldGoToMigration = data?.filter((operator) => operator?.isMigration)

    if (shouldGoToMigration?.length) {
      navigate('/cadastro/migrar', {
        state: {
          operatorsInfo: shouldGoToMigration,
          quantity: shouldGoToMigration.length,
          operators: data,
          token,
          cnpj,
        },
      })

      return
    }

    setIsSelectingBenefits(false)
  }

  const iconSelected = (index) => {
    if (tabIndex > index) {
      return (
        <svg width='21' height='15' viewBox='0 0 21 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M19.8838 0.78536C20.4519 1.30968 20.4519 2.22724 19.8838 2.75156L8.69833 13.9371C8.174 14.5051 7.25644 14.5051 6.73212 13.9371L1.13936 8.34432C0.571347 7.82 0.571347 6.90244 1.13936 6.37812C1.66368 5.81011 2.58124 5.81011 3.10557 6.37812L7.69338 10.9659L17.9176 0.78536C18.442 0.217345 19.3595 0.217345 19.8838 0.78536Z'
            fill='#E7EBF0'
          />
        </svg>
      )
    }

    if (tabIndex === index) {
      return (
        <svg width='29' height='28' viewBox='0 0 29 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M10.3453 4.44988C10.758 5.11053 10.5783 5.92346 9.91807 6.31867C6.8166 8.19827 5.28005 11.9307 6.29762 15.548C7.55183 20.0064 12.1664 22.6991 16.6944 21.5353C21.1802 20.3824 23.8019 15.8298 22.5477 11.3713C21.5302 7.75407 18.2001 5.27242 14.5307 5.1331C13.7522 5.1077 13.1702 4.49055 13.1806 3.72114C13.191 2.95172 13.8344 2.33533 14.613 2.36073C19.435 2.56461 23.8836 5.79613 25.2561 10.6752C26.9362 16.6478 23.4185 22.6936 17.4517 24.2272C11.4425 25.7717 5.26943 22.2167 3.58927 16.2441C2.21674 11.365 4.32751 6.44762 8.39227 3.95961C9.05251 3.5644 9.93256 3.78923 10.3453 4.44988Z'
            fill='#FCFDFF'
          />
        </svg>
      )
    }

    return (
      <svg width='35' height='35' viewBox='0 0 35 35' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <circle cx='17.5625' cy='17.5449' r='17' fill='#E0E0E0' />
        <circle cx='17.5634' cy='17.5458' r='7.55556' fill='#FCFDFF' />
      </svg>
    )
  }

  return (
    <>
      {isSelectingBenefits ? (
        <Paper sx={{ padding: '64px 32px' }}>
          {fetchBenefitsState.loading ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Spinner />
            </Box>
          ) : (
            <CategorizedItemsSelection
              title={'Selecionar benefícios'}
              searchHolder={'Buscar benefício...'}
              items={fetchBenefitsState.response?.data}
              categories={categories}
              getItemCategory={(category) => category.benefitType}
              getItemName={(item) => item.cardName}
              getItemId={(item) => item.operatorCardId}
              onClose={() => {
                dispatch(companyDataClear())
                navigate('/cadastro')
              }}
              onConfirm={onConfirm}
              value={operatorsCard}
              onChange={setOperatorsCard}
            />
          )}
        </Paper>
      ) : (
        <Tabs
          selectedTabClassName='is-selected'
          disabledTabClassName='is-disabled'
          selectedTabPanelClassName='is-selected'
          selectedIndex={tabIndex}
          onSelect={handleTabNavigation}
        >
          <TabList>
            <Tab disabled={tabIndex > 2} tab={0} currentTab={tabIndex} name='DADOS GERAIS'>
              {iconSelected(0)}
            </Tab>
            <Tab disabled={tabIndex > 2} tab={1} currentTab={tabIndex} name='ENDEREÇO'>
              {iconSelected(1)}
            </Tab>
            <Tab disabled={tabIndex > 2} tab={2} currentTab={tabIndex} name='CONTATO'>
              {iconSelected(2)}
            </Tab>
            <Tab disabled tab={3} currentTab={tabIndex} name='DOCUMENTOS'>
              {iconSelected(3)}
            </Tab>
          </TabList>

          <TabPanel>
            <CompanyData
              companyData={companyData.data}
              changeForm={changeForm}
              controlNavigation={controlNavigation}
              mode={mode}
              saveOnStore={(data) => {
                dispatch(companyDataStore(data))
              }}
              nextTab={nextTab}
              returnToList={returnToList}
            />
          </TabPanel>

          <TabPanel>
            <Addresses
              companyData={companyData.data}
              changeForm={changeForm}
              controlNavigation={controlNavigation}
              mode={mode}
              saveOnStore={(data) => {
                dispatch(companyAddressesStore(data))
              }}
              nextTab={nextTab}
              prevTab={prevTab}
              returnToList={returnToList}
            />
          </TabPanel>

          <TabPanel>
            <Contacts
              companyData={companyData.data}
              changeForm={changeForm}
              controlNavigation={controlNavigation}
              mode={mode}
              saveOnStore={(data) => {
                dispatch(companyContactsStore(data))
              }}
              nextTab={nextTab}
              prevTab={prevTab}
              sendToApi={setSendToApi}
              loading={loading}
            />
          </TabPanel>

          <TabPanel>
            <Documents
              companyData={companyData.data}
              changeForm={changeForm}
              controlNavigation={controlNavigation}
              mode={mode}
              saveOnStore={(data) => {
                dispatch(companyDocumentsStore(data))
              }}
              nextTab={nextTab}
              prevTab={prevTab}
              done={done}
            />
          </TabPanel>
        </Tabs>
      )}
    </>
  )
}

export default Form
