import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useEffect } from 'react'

import api from '@/infra/api'
import { AxiosResponse } from 'axios'

interface IUseSWR {
  orderId: string
  orderType: 'cards' | 'recharge'
}

interface IPixData {
  numberOfColaborators: number
  dueDate: null
  orderDate: Date
  code: number
  status: number
  operatorName: string
  confirmation: {
    paymentFeeValue: number
    transferFeeValue: number
    useOfWallet: number
    useOfCheckingAccount: boolean
    issFeeValue: number
    subTotalValue: number
    totalValue: number
    totalAmountPayable: number
  }
  ticketingBalance: number
  oldTicketingBalance: number
  walletBalance: number
  companyCity: string
  companyName: string
  tradeName: string
  pix: {
    qrCode: string
  }
}

export const usePix = ({ orderId, orderType }: IUseSWR) => {
  const client = useQueryClient()

  const { data, isLoading } = useQuery<AxiosResponse<IPixData>>({
    queryKey: ['pix', orderId, orderType],
    queryFn: () => api.get(`rh-bff/${orderType}/new/confirmation/${orderId}/pix`),
    refetchInterval: 5000,
    retry: 100,
    retryDelay: 5000,
    enabled: Boolean(orderId && orderType),
  })

  const { mutate } = useMutation<AxiosResponse<IPixData>>({
    mutationFn: () => api.patch(`rh-bff/recharge/new/confirmation/${orderId}/regenerate-pix`),
  })

  useEffect(() => {
    if (data?.status > 3) {
      client.invalidateQueries(['orderDetail'])
      client.invalidateQueries(['orderDetailItems'])
    }
  }, [data])

  return {
    data: data?.data,
    loading: isLoading,
    generatePix: mutate,
  }
}
