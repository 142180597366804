import React, { useEffect, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller } from 'react-hook-form'
import { ErrorMessage, ErrorWrapper, Spacing, Spinner } from 'presentation/components'
import { ButtonWrapper, Centralizer } from './styles'
import schema from './schema'
import { getResaleSiteFromUrl, removeSpecialCharacters } from '@/utils/functionUtils'
import { useDispatch } from 'react-redux'
import Modal from '../../modal'
import { companyDataStore } from '@/store/modules/empresa/actions'
import { pushNotification } from '@/store/modules/notification/actions'
import errorHandler from '@/utils/errorHandler'
import { checkCPNJ } from '@/services/companiesServices'
import { useNavigate } from 'react-router-dom'
import useService from '@/main/hooks/useService'
import { getRegisterCompanyToken } from '@/services/authServices'
import { Button, Typography, Paper, MaskedTextField } from '@stationkim/front-ui'

const ACTIONS = (navigate, token, cnpj) => ({
  'Não Cadastrado': {
    modal: 'Não foi encontrado cadastro, deseja cadastrar?',
    action: () => {
      navigate('/cadastro', {
        state: { mode: 'FORM', token, cnpj },
      })
    },
  },
  Cadastrado: {
    modal: 'Foi encontrado cadastro para esse CNPJ, deseja fazer o login?',
    action: () => navigate('/'),
  },
})

const InsertCnpj = () => {
  const navigate = useNavigate()
  const [isModalVisible, setIsModalVisible] = useState(null)
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  })

  const [genericTokenState, genericTokenStateRequest] = useService(getRegisterCompanyToken)
  const token = genericTokenState?.response?.data?.accessToken

  useEffect(() => {
    genericTokenStateRequest(getResaleSiteFromUrl())
  }, [dispatch])

  async function callApi(cnpj) {
    setLoading(true)
    const { error, response } = await checkCPNJ(cnpj, token)

    if (response) {
      const status = response?.data ? 'Cadastrado' : 'Não Cadastrado'
      dispatch(companyDataStore({ cnpj }))
      setIsModalVisible(ACTIONS(navigate, token, cnpj)[status])
    } else {
      dispatch(pushNotification(errorHandler(error)))
    }
    setLoading(false)
  }

  function onSubmit(data) {
    const cnpjWithoutMask = removeSpecialCharacters(data.cnpj)
    callApi(cnpjWithoutMask)
  }

  return (
    <Paper>
      <Centralizer>
        <Typography variant='h2'>Cadastrar Empresa</Typography>
        <Spacing top='64px' />
        {loading || genericTokenState.loading ? (
          <Spinner />
        ) : (
          <>
            <Typography textAlign='center'>Por favor, informe o CNPJ da empresa que deseja cadastrar.</Typography>
            <Spacing top='40px' />
            <span></span>
            <form onSubmit={handleSubmit(onSubmit)} id='personalData'>
              <ErrorWrapper error={errors.cnpj}>
                <label>CNPJ</label>
                <Spacing top='8px' />
                <Controller
                  control={control}
                  name='cnpj'
                  render={({ field: { onChange, value, ref } }) => (
                    <MaskedTextField
                      mask='cnpj'
                      placeholder='00.000.000/0000-00'
                      value={value}
                      getInputRef={ref}
                      onChange={onChange}
                    />
                  )}
                />
                {errors.cnpj && <ErrorMessage>{errors.cnpj.message}</ErrorMessage>}
              </ErrorWrapper>
              <Spacing top='40px' />
              <ButtonWrapper>
                <Button width='160px' variant='outlined' onClick={() => navigate('/')}>
                  Cancelar
                </Button>
                <Button width='160px' variant='contained' type='submit'>
                  Continuar
                </Button>
              </ButtonWrapper>
            </form>
          </>
        )}
      </Centralizer>
      {!!isModalVisible && (
        <Modal
          open={!!isModalVisible}
          message={isModalVisible.modal}
          confirm={() => isModalVisible.action()}
          cancel={() => setIsModalVisible(false)}
        />
      )}
    </Paper>
  )
}

export default InsertCnpj
