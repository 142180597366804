import styled from 'styled-components'
import { Select as SelectComponent } from '@stationkim/front-ui'
import { Row as GridRow } from 'react-grid-system'

export const Content = styled.div`
  width: 90%;
`

export const Label = styled.div`
  margin-bottom: ${({ variant }) => (variant ? '0' : '10px')};
  white-space: nowrap;
  font-size: 14px;
  color: rgba(6, 6, 6, 1);
`

export const Select = styled(SelectComponent)`
  :&  {
    span {
      font-size: 14px !imortant;
    }
  }
`

export const Row = styled(GridRow)`
  margin-top: 32px;
  &:first-child {
    margin-top: 0;
  }
`

export const Spacing = styled.div`
  margin-top: 32px;
`

export const Fieldset = styled.fieldset`
  border: none;
`

export const Hr = styled.div`
  width: 100%;
  border: 1px solid var(--color-secundary-05);
`

export const AddContact = styled.div`
  color: rgba(25, 118, 210, 1);
  font-size: 16px;
  font-weight: var(--font-weight-semi-bold);
  letter-spacing: 0.29px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
`

export const Trash = styled.div`
  > svg {
    cursor: pointer;
    height: 22px;
    width: 22px;
    transition: all 0.25s;
    :hover {
      stroke: var(--color-primary-01);
    }
  }
`

export const SendFormConfirmation = styled.div`
  text-align: center;
  white-space: pre-wrap;

  p:first-child {
    font-size: 1.5rem;
    margin-bottom: 16px;
  }

  p + p {
    margin-bottom: 16px;
  }

  p:last-child {
    font-size: 1.125rem;
  }
`
