import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'

import { getResaleSiteFromUrl } from '@/utils/functionUtils'
import api from '@/infra/api'
import { useNotifications } from '@/main/hooks'
import { EMessageType } from '@/main/store'

export interface IRegister {
  name: string
  email: string
  confirmEmail: string
}

export const useRegister = () => {
  const resaleSite = getResaleSiteFromUrl()
  const navigate = useNavigate()
  const { push } = useNotifications()

  const [open, setOpen] = useState(false)

  const { mutate: register, isLoading } = useMutation({
    mutationFn: async (payload: IRegister) => await api.post('rh-bff/profile/create', { ...payload, site: resaleSite }),
    onSuccess() {
      push({ content: 'Usuário cadastrado com sucesso!', type: EMessageType.Success })

      navigate('/login')
    },
    onError(data) {
      setOpen(true)
    },
    meta: {
      silent: true,
    },
  })

  return {
    register,
    isLoading,
    setOpen,
    open,
  }
}
