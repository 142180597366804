import { useState, useEffect, useContext } from 'react'
import { QRCodeSVG } from 'qrcode.react'
import moment from 'moment'

import copy from '@/presentation/assets/icons/copy.png'
import loadingCopy from '@/presentation/assets/icons/spinner-pix-copy.svg'
import qrcodeFinished from '@/presentation/assets/icons/qrcode-finished.svg'
import loadingPix from '@/presentation/assets/icons/loading-pix.png'

import { Button, Divider, Grid, Typography, Tooltip, Box } from 'presentation/components/atoms'
import { formatMoney } from '@/utils/functionUtils'

import { Steps, Total, Clipboard, QRCodeContent, Expire, Expired } from './styles'
import { useCountdown } from './hooks/useCountdown'
import { OrderTrackingContext } from '@/presentation/pages/orderTracking/orderTracking'

interface PixProps {
  orderType: 'cards' | 'recharge'
}

export const Pix = ({ orderType }: PixProps) => {
  const {
    pix: { qrCode, value, orderDate },
  } = useContext(OrderTrackingContext)
  const isLoading = !qrCode
  const targetDate = moment(orderDate).add(1, 'day') as unknown as Date
  const [, hours, minutes, seconds] = useCountdown({ targetDate })

  const [isCopyTipOpen, setIsCopyTipOpen] = useState(false)

  const copyText = () => {
    navigator.clipboard.writeText(qrCode)
    const element: any = document.querySelector('#rawPix')
    element.select()
  }

  useEffect(() => {
    setTimeout(() => {
      setIsCopyTipOpen(false)
    }, 1000)
  }, [isCopyTipOpen])

  const expire =
    hours + minutes + seconds > 0 ? (
      <>
        <h4>Escaneie o código para efetuar o pagamento</h4>
        <Steps>
          <li>1. Acesse o app de pagamentos</li>
          <li>2. Escolha pagamento via PIX</li>
          <li>3. Escaneie o código:</li>
        </Steps>
        <Total>
          Valor: <span>R$ {formatMoney(value)}</span>
        </Total>
        <Expire>
          <p>
            Expira em{' '}
            <span>{`${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
              .toString()
              .padStart(2, '0')}`}</span>{' '}
            (Pague até {moment(targetDate).locale('pt-br').format('DD/MM/YYYY HH:mm').replace(' ', ' às ')})
          </p>
        </Expire>
      </>
    ) : (
      <Expired>QRCode expirado!</Expired>
    )

  return (
    <>
      <Typography variant='h3' sx={{ fontWeight: 500 }}>
        Pague o valor do pedido via Pix
      </Typography>
      <Typography sx={{ marginTop: '24px' }}>
        <>
          O pagamento será aprovado instantaneamente. <br />
          Copie o código abaixo e use o internet banking para pagar.
        </>
      </Typography>
      <>
        <Divider sx={{ margin: '32px 0' }} />
        <Grid container columnSpacing='24px' rowSpacing='8px'>
          <Grid item xs={12} md={8}>
            {orderDate ? expire : null}
          </Grid>
          <Grid item xs={12} md={4}>
            <QRCodeContent>
              {isLoading || !qrCode ? (
                <>
                  <img src={qrcodeFinished} alt='qrcode' />
                  <img src={loadingPix} alt='loading pix' className='loading' />
                </>
              ) : (
                <QRCodeSVG width={200} height={200} value={qrCode} />
              )}
            </QRCodeContent>
          </Grid>
          <Grid item xs={12}>
            <Clipboard>
              <input readOnly value={isLoading ? 'Aguarde até que o código seja gerado...' : qrCode} id='rawPix' />
              {isLoading ? (
                <img src={loadingCopy} alt='loading-copy' className='loading' />
              ) : (
                <Tooltip
                  title='Código copiado'
                  placement='top'
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  open={isCopyTipOpen}
                >
                  <img
                    src={copy}
                    alt='copy'
                    onClick={() => {
                      copyText()
                      setIsCopyTipOpen(true)
                    }}
                  />
                </Tooltip>
              )}
            </Clipboard>
            {!isLoading ? (
              <Button
                variant='contained'
                onClick={() => {
                  copyText()
                  setIsCopyTipOpen(true)
                }}
              >
                Copiar código
              </Button>
            ) : null}
          </Grid>
        </Grid>
      </>
    </>
  )
}
