import { useState, useEffect, useContext } from 'react'
import { QRCodeSVG } from 'qrcode.react'
import moment from 'moment'

import copy from '@/presentation/assets/icons/copy.png'
import loadingCopy from '@/presentation/assets/icons/spinner-pix-copy.svg'
import qrcodeFinished from '@/presentation/assets/icons/qrcode-finished.svg'
import qrcodeExpired from '@/presentation/assets/icons/qrcode-expired.svg'
import loadingPix from '@/presentation/assets/icons/loading-pix.png'

import { Button, Divider, Grid, Typography, Tooltip } from 'presentation/components/atoms'
import { formatMoney } from '@/utils/functionUtils'

import { Steps, Total, Clipboard, QRCodeContent, Expire, Expired } from './styles'
import { useCountdown } from './hooks/useCountdown'
import { OrderTrackingContext } from '@/presentation/pages/orderTracking/oldOrderTracking/orderTracking'

interface PixProps {
  orderType: 'cards' | 'recharge'
}

export const Pix = ({ orderType }: PixProps) => {
  const {
    pix: { qrCode, value, dueDate },
    generatePix,
  } = useContext(OrderTrackingContext)

  const [loading, setLoading] = useState(false)

  const isLoading = !qrCode || loading
  const isExpired = moment(new Date(), 'DD/MM/YYYY  HH:mm').locale('pt-br').isSameOrAfter(dueDate)
  const [, hours, minutes, seconds] = useCountdown({
    targetDate: moment(new Date(dueDate), 'DD/MM/YYYY  HH:mm').locale('pt-br').toDate(),
  })

  const [isCopyTipOpen, setIsCopyTipOpen] = useState(false)

  const copyText = () => {
    navigator.clipboard.writeText(qrCode)
    const element: any = document.querySelector('#rawPix')
    element.select()
  }

  useEffect(() => {
    setTimeout(() => {
      setIsCopyTipOpen(false)
    }, 1000)
  }, [isCopyTipOpen])

  useEffect(() => {
    if (!isExpired) {
      setLoading(false)
    }
  }, [isExpired])

  const expire = isExpired ? (
    <Expired>
      <h4>Esse pix expirou!</h4>
      <p>Gere um novo QR Code para continuar</p>
      <Total>
        Valor: <span>R$ {formatMoney(value)}</span>
      </Total>
      <small>Expirou em {moment(dueDate).locale('pt-br').format('DD/MM/YYYY HH:mm').replace(' ', ' às ')}</small>
    </Expired>
  ) : (
    <>
      <h4>Escaneie o código para efetuar o pagamento</h4>
      <Steps>
        <li>1. Acesse o app de pagamentos</li>
        <li>2. Escolha pagamento via PIX</li>
        <li>3. Escaneie o código:</li>
      </Steps>
      <Total>
        Valor: <span>R$ {formatMoney(value)}</span>
      </Total>
      <Expire>
        <p>
          Expira em{' '}
          <span>{`${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
            .toString()
            .padStart(2, '0')}`}</span>{' '}
          (Pague até {moment(dueDate).locale('pt-br').format('DD/MM/YYYY HH:mm').replace(' ', ' às ')})
        </p>
      </Expire>
    </>
  )

  const generateQRCode = () => {
    if (isLoading) {
      return (
        <>
          <img src={qrcodeFinished} alt='qrcode' />
          <img src={loadingPix} alt='loading pix' className='loading' />
        </>
      )
    }

    if (isExpired) {
      return (
        <>
          <img src={qrcodeFinished} alt='qrcode' />
          <img src={qrcodeExpired} alt='loading pix' className='loading' />
        </>
      )
    }

    return <QRCodeSVG width={200} height={200} value={qrCode} />
  }

  const generateAction = isExpired ? (
    <Button
      variant='contained'
      onClick={() => {
        generatePix()
        setLoading(true)
      }}
    >
      Gerar novo QR Code{' '}
      <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M15.8359 6.66536L12.5026 9.9987H15.0026C15.0026 12.757 12.7609 14.9987 10.0026 14.9987C9.16094 14.9987 8.36094 14.7904 7.66927 14.4154L6.4526 15.632C7.4776 16.282 8.69427 16.6654 10.0026 16.6654C13.6859 16.6654 16.6693 13.682 16.6693 9.9987H19.1693L15.8359 6.66536ZM5.0026 9.9987C5.0026 7.24036 7.24427 4.9987 10.0026 4.9987C10.8443 4.9987 11.6443 5.20703 12.3359 5.58203L13.5526 4.36536C12.5276 3.71536 11.3109 3.33203 10.0026 3.33203C6.31927 3.33203 3.33594 6.31536 3.33594 9.9987H0.835938L4.16927 13.332L7.5026 9.9987H5.0026Z'
          fill='white'
        />
      </svg>
    </Button>
  ) : (
    <>
      <Clipboard>
        <input readOnly value={isLoading ? 'Aguarde até que o código seja gerado...' : qrCode} id='rawPix' />
        {isLoading ? (
          <img src={loadingCopy} alt='loading-copy' className='loading' />
        ) : (
          <Tooltip
            title='Código copiado'
            placement='top'
            disableFocusListener
            disableHoverListener
            disableTouchListener
            open={isCopyTipOpen}
          >
            <img
              src={copy}
              alt='copy'
              onClick={() => {
                copyText()
                setIsCopyTipOpen(true)
              }}
            />
          </Tooltip>
        )}
      </Clipboard>
      {!isLoading ? (
        <Button
          variant='contained'
          onClick={() => {
            copyText()
            setIsCopyTipOpen(true)
          }}
        >
          Copiar código
        </Button>
      ) : null}
    </>
  )

  return (
    <>
      <Typography variant='h3' sx={{ fontWeight: 500 }}>
        Pague o valor do pedido via Pix
      </Typography>
      <Typography sx={{ marginTop: '24px' }}>
        <>
          O pagamento será aprovado instantaneamente. <br />
          Copie o código abaixo e use o internet banking para pagar.
        </>
      </Typography>
      <>
        <Divider sx={{ margin: '32px 0' }} />
        <Grid container columnSpacing='24px' rowSpacing='8px'>
          <Grid item xs={12} md={8}>
            {dueDate ? expire : null}
          </Grid>
          <Grid item xs={12} md={4}>
            <QRCodeContent isLoading={isLoading} className='loading'>
              {generateQRCode()}
            </QRCodeContent>
          </Grid>
          <Grid item xs={12}>
            {generateAction}
          </Grid>
        </Grid>
      </>
    </>
  )
}
