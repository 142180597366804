import { useState } from 'react'
import { faEnvelope, faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons'
import { faLock } from '@fortawesome/free-solid-svg-icons'
import { validateEmail } from 'validations-br'
import { Controller } from 'react-hook-form'
import { TextField } from '@/presentation/components/molecules'
import { Notification } from '@/presentation/components'
import { Button, CircularProgress, Link, Icon, Typography } from '@/presentation/components/atoms'
import { useForm, useSession, ILogin, useAuth } from '@/main/hooks'
import { Logo, StyledBox, StyledPaper } from './styles'
import { getResaleSiteFromUrl } from '@/utils/functionUtils'

const Login = () => {
  const resaleSite = getResaleSiteFromUrl()
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)
  const { login, isLoading } = useAuth()
  const { sessionData } = useSession(resaleSite)
  const resaleData = sessionData?.resale

  const { control, handleSubmit } = useForm({
    defaultValues: {
      email: '',
      password: '',
      site: resaleSite,
    },
  })
  const onSubmit = (data: ILogin) => {
    login(data)
  }

  const handleImageError = (e) => (e.target.style.display = 'none')
  return (
    <StyledBox>
      <Notification />
      <StyledPaper>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <Logo
              key={Date.now()}
              src={`${resaleData?.images?.login}?${Date.now()}`}
              onError={handleImageError}
              sx={{ display: resaleData?.images?.login ? 'initial' : 'none' }}
              alt='Logo'
            />
            <Typography variant='h1'>Bem-vindo!</Typography>
            <Typography sx={{ marginBottom: '16px' }}>
              Para acessar sua área Backoffice, insira seu e-mail <br />e senha cadastrados na plataforma.
            </Typography>
            <Controller
              control={control}
              name='email'
              rules={{
                required: 'Campo requerido',
                validate: {
                  isEmailInValid: (value) => validateEmail(value) || 'Esse não é um e-mail valido',
                },
              }}
              render={({ field: { value, onChange }, fieldState: { error, isDirty } }) => (
                <TextField
                  label='E-mail'
                  placeholder='exemplo@mail.com'
                  startAdornment={<Icon icon={faEnvelope} />}
                  value={value}
                  helperText={error?.message}
                  onChange={onChange}
                  error={Boolean(error)}
                  success={!error && isDirty}
                  autoComplete='email'
                />
              )}
            />
            <Controller
              control={control}
              name='password'
              rules={{
                required: 'Campo requerido',
              }}
              render={({ field: { value, onChange }, fieldState: { error, isDirty } }) => (
                <TextField
                  label='Senha'
                  placeholder='Informe a senha'
                  startAdornment={<Icon icon={faLock} />}
                  endAdornment={
                    isPasswordVisible ? (
                      <Icon icon={faEyeSlash} onClick={() => setIsPasswordVisible(false)} />
                    ) : (
                      <Icon icon={faEye} onClick={() => setIsPasswordVisible(true)} />
                    )
                  }
                  value={value}
                  helperText={error?.message}
                  onChange={onChange}
                  error={Boolean(error)}
                  success={!error && isDirty}
                  type={isPasswordVisible ? 'text' : 'password'}
                  autoComplete='password'
                />
              )}
            />
            <Link to={'/esqueci_minha_senha'}>Esqueci minha senha</Link>
            <Button sx={{ marginTop: '40px' }} variant='contained' type='submit' fullWidth>
              Fazer Login
            </Button>
            <Button sx={{ marginTop: '10px' }} variant='outlined' fullWidth type='button'>
              <Link to='/cadastro/usuario'>Novo Cadastro</Link>
            </Button>
          </form>
        )}
      </StyledPaper>
    </StyledBox>
  )
}

export default Login
