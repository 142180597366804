import styled from 'styled-components'

export const Centralizer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 390px;
  padding: 60px 0;
  min-height: 64vh;
  margin: 0 auto;

  form {
    width: 100%;
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 64px;
  width: 100%;

  button {
    width: 165px;
  }
`
