import styled from 'styled-components'
import { TabList as TabListComponent, Tab as TabComponent } from 'presentation/components'

export const TabPanelHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const ToggleHolder = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ToggleMod = styled.span`
  width: 80px;
  transform: scale(0.7);
`

export const TabList = styled(TabListComponent)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 180px;
  height: 140px;
`

export const Tab = styled(TabComponent)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-size: 16px;
  color: rgba(97, 97, 97, 1);
  border-radius: 100%;
  width: 48px;
  height: 48px;
  background-color: ${({ currentTab, tab }) => (currentTab >= tab ? 'rgba(25, 118, 210, 1)' : '#fff')};

  svg {
    position: absolute;
    z-index: 1;
  }

  &.is-selected {
    background-color: rgba(25, 118, 210, 1);
  }

  div {
    width: 200px;
    border-left: 0;
    border-bottom: ${({ tab, currentTab }) =>
      currentTab > tab
        ? '1px solid rgba(25, 118, 210, 1)'
        : `1px dashed ${tab > currentTab ? 'rgba(224, 224, 224, 1)' : 'rgba(25, 118, 210, 1)'}`};
    left: -180px;
    top: -2px;
    z-index: 0;
  }

  &::after {
    position: relative;
    content: ${({ name }) => `"${name}"`};
    border: 0;
    font-weight: ${({ tab, currentTab }) => (currentTab >= tab ? '700' : '400')};
    font-size: 16px;
    line-height: 100%;
    letter-spacing: 0px;
    text-align: center;
    bottom: -50px;
    left: 0;
    color: ${({ tab, currentTab }) => (currentTab === tab ? 'rgba(6, 6, 6, 1)' : 'rgba(97, 97, 97, 1)')};
  }

  & + li::before {
    border: 0;
  }
`
