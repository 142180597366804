import { CARD_USAGE, CARD_STATUS } from '@/utils/constants'
import { productStatus } from '@/utils/status'
import { BENEFIT_TYPES } from 'utils/constants'
import DotMenu from 'presentation/components/dotMenu'

import { TableCell, TableRow, TableBody as Body } from 'presentation/components/atoms'
import { CentralizeText, StatusDot, Wrapper } from '../../../../styles'
import { TextDecoration } from 'presentation/components'
import { useDispatch } from 'react-redux'
import { pushNotification } from 'store/modules/notification/actions'
import { useAccessProfile } from '@/main/hooks/useAccessProfile'

export const TableBody = ({
  cards,
  setCardId,
  setIsAssociatingCard,
  setCardToBlock,
  setCardIdToDesassociate,
  setCardToChangeStatus,
}) => {
  const { resources } = useAccessProfile({ resource: 'cartoes' })

  const dispatch = useDispatch()

  const associateCard = (cardId) => {
    setIsAssociatingCard(true)
    setCardId(cardId)
  }

  function handleBlockClick(card) {
    if (!card.employeeName) {
      dispatch(
        pushNotification({
          type: 'warning',
          content: 'Associe um novo colaborador para conseguir bloquear',
        }),
      )
    } else {
      setCardToBlock(card)
    }
  }

  return (
    <Body>
      {cards?.map((card, index) => (
        <TableRow key={'card_' + index} style={{ cursor: 'pointer' }}>
          <TableCell id={'cards_body_beneficio_nome_' + index}>{card.benefitName}</TableCell>
          <TableCell id={'cards_body_numero_' + index}>{card.number}</TableCell>
          <TableCell
            onClick={(e) => {
              if (!resources({ item: 'cartoes_associar' })) {
                return null
              }

              e.stopPropagation()
              if (card.status === CARD_STATUS.ACTIVE && !card.employeeName) associateCard(card.id)
            }}
            id={'cards_body_colaborador_' + index}
          >
            {!card.employeeName && card.status === CARD_STATUS.ACTIVE ? (
              resources({ item: 'cartoes_associar' }) ? (
                <TextDecoration variant='underlined'>Associar Colaborador</TextDecoration>
              ) : null
            ) : (
              card.employeeName
            )}
          </TableCell>
          <TableCell id={'cards_body_metricula_operadora_' + index}>{card.employeeIntegrationCode}</TableCell>
          <TableCell id={'cards_body_beneficio_' + index}>{BENEFIT_TYPES[card.benefitType]}</TableCell>
          <TableCell id={'cards_body_valor_diario_' + index}>
            {card?.dailyValue?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) || (
              <CentralizeText>-</CentralizeText>
            )}
          </TableCell>
          {resources({ item: 'cartoes_saldo' }) ? (
            <TableCell id={'cards_body_saldo_' + index}>
              {(() => {
                if (card.usage === CARD_USAGE.DEALER)
                  return card.balance.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                else return <CentralizeText>-</CentralizeText>
              })()}
            </TableCell>
          ) : null}
          <TableCell id={'cards_body_status_' + index}>
            <Wrapper>
              <StatusDot status={productStatus[card.status]} />
              {productStatus[card.status]?.toLowerCase()}
            </Wrapper>
          </TableCell>
          <TableCell style={{ textAlign: 'center' }} id={'cards_body_acoes_' + index}>
            {card.status !== CARD_STATUS.BLOQUED && (
              <DotMenu>
                {resources({ item: 'cartoes_gerenciar' }) ? (
                  <DotMenu.Option id='cards_action_activeOrInactive' onClick={(e) => setCardToChangeStatus(card)}>
                    {card.status === CARD_STATUS.ACTIVE ? 'Inativar' : 'Ativar'}
                  </DotMenu.Option>
                ) : null}
                {card.employeeName &&
                  (resources({ item: 'cartoes_gerenciar' }) ? (
                    <DotMenu.Option id='cards_action_disassociate' onClick={() => setCardIdToDesassociate(card.id)}>
                      Desassociar
                    </DotMenu.Option>
                  ) : null)}

                {resources({ item: 'cartoes_gerenciar' }) ? (
                  <DotMenu.Option
                    id='cards_action_block'
                    onClick={() => {
                      handleBlockClick(card)
                    }}
                  >
                    Bloquear
                  </DotMenu.Option>
                ) : null}
              </DotMenu>
            )}
          </TableCell>
        </TableRow>
      ))}
    </Body>
  )
}
