import React, { useEffect, useState } from 'react'
import { Container, Col } from 'react-grid-system'
import { useForm, Controller, useFieldArray } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { ErrorMessage, ErrorWrapper, Spacing, Icon, TextDecoration } from 'presentation/components'
import { AddContact, Fieldset, Label, SendFormConfirmation, Trash, Row, Select } from './style'
import { useDispatch } from 'react-redux'
import schema from '../schema/contacts'
import Modal from '../../modal'
import { companyContactsClear } from '@/store/modules/empresa/actions'
import { FORM_MODE } from '@/utils/constants'
import { useLocation } from 'react-router-dom'
import axios from 'axios'
import { Typography, InputBase as Input, Button, MaskedTextField } from '@stationkim/front-ui'

const CONTACS_LIMIT = 3

const Contacts = ({
  changeForm,
  controlNavigation,
  mode,
  nextTab,
  prevTab,
  saveOnStore,
  companyData,
  sendToApi,
  loading,
}) => {
  const readOnly = mode === FORM_MODE.VIEW ? true : false
  const [cancelModal, setCancelModal] = useState(false)
  const [removeContact, setRemoveContact] = useState(null)
  const [finishForm, setFinishForm] = useState(false)
  const [contactTypes, setContactTypes] = useState([])
  const dispatch = useDispatch()
  const {
    state: { token },
  } = useLocation()

  let defaultValues = {}

  function putMainAsFirst() {
    const mainIndex = companyData?.contacts?.findIndex((contact) => contact.useThisContactAsMain === true)
    if (mainIndex > 0) {
      const aux = companyData?.contacts[0]
      companyData.contacts[0] = companyData.contacts[mainIndex]
      companyData.contacts[mainIndex] = aux
    }
  }

  if (companyData != null) {
    companyData?.contacts && putMainAsFirst()
    defaultValues = { contacts: (companyData?.contacts?.length > 0 && companyData.contacts) || [{ fullName: '' }] }
  }

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid },
    getValues,
    setValue,
  } = useForm({
    defaultValues: defaultValues,
    mode: 'onChange',
    resolver: yupResolver(schema),
    shouldUnregister: true,
    context: { register: !mode ? true : false },
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'contacts',
  })

  useEffect(() => {
    const canPass = isValid
    controlNavigation(canPass)
  }, [isValid, controlNavigation])

  useEffect(() => {
    ;(async () => {
      const response = await axios.get(`${process.env.REACT_APP_API_HOST}/rh-bff/contact/contact-types`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      setContactTypes(response?.data?.data)
    })()
  }, [])

  const onSubmit = (data) => {
    saveOnStore(data)
    setFinishForm(true)
  }

  changeForm(() => {
    handleSubmit(saveOnStore)(getValues())
  })

  function generateContactFields() {
    return fields.map((_, index) => {
      const hasError = errors.contacts
        ? {
            cpf: errors.contacts[index]?.cpf,
            phone: errors.contacts[index]?.phone,
            fullName: errors.contacts[index]?.fullName,
            email: errors.contacts[index]?.email,
            extension: errors.contacts[index]?.extension,
            type: errors.contacts[index]?.type,
          }
        : null

      return (
        <React.Fragment key={_.id}>
          <Row gutterWidth={40}>
            <Col sm={12}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant='h2'>Informações de Contato</Typography>

                {index !== 0 && (
                  <Trash>
                    <Icon
                      name='trash'
                      onClick={() => {
                        setRemoveContact(() => () => {
                          remove(index)
                          setRemoveContact(null)
                        })
                      }}
                    />
                  </Trash>
                )}
              </div>
            </Col>
            <Col>
              <Spacing bottom='32px' />
            </Col>
            <Col sm={12}>
              <Typography>Informe o contato principal</Typography>
            </Col>
          </Row>
          <Row gutterWidth={40}>
            <Col sm={3}>
              <ErrorWrapper error={hasError?.fullName}>
                <Label>Nome Completo *</Label>
                <Input placeholder='Nome Completo' maxLength='130' {...register(`contacts.${index}.fullName`)} />
                {hasError?.fullName && <ErrorMessage>{errors.contacts[index].fullName.message}</ErrorMessage>}
              </ErrorWrapper>
            </Col>
            <Col sm={3}>
              <ErrorWrapper error={hasError?.email}>
                <Label>E-mail corporativo *</Label>
                <Input
                  maxLength='256'
                  placeholder='colaborador@empresa.com.br'
                  {...register(`contacts.${index}.email`)}
                />
                {hasError?.email && <ErrorMessage>{errors.contacts[index].email.message}</ErrorMessage>}
              </ErrorWrapper>
            </Col>
            <Col sm={3}>
              <ErrorWrapper error={hasError?.cpf}>
                <Label>CPF *</Label>
                <Controller
                  control={control}
                  name={`contacts.${index}.cpf`}
                  render={({ field: { onChange, value, ref } }) => (
                    <MaskedTextField
                      placeholder='000.000.000-00'
                      mask={'cpf'}
                      value={value}
                      getInputRef={ref}
                      disabled={mode === FORM_MODE.EDIT}
                      onChange={onChange}
                    />
                  )}
                />
                {hasError?.cpf && <ErrorMessage>{errors.contacts[index].cpf.message}</ErrorMessage>}
              </ErrorWrapper>
            </Col>
          </Row>
          <Row gutterWidth={40}>
            <Col sm={3}>
              <ErrorWrapper error={hasError?.phone}>
                <Label variant>Telefone *</Label>
                <Controller
                  control={control}
                  name={`contacts.${index}.phone`}
                  render={({ field: { onChange, value, ref } }) => (
                    <MaskedTextField
                      mask={'cellphone'}
                      placeholder='(00) 00000-0000'
                      value={value}
                      getInputRef={ref}
                      onChange={onChange}
                    />
                  )}
                />
                {hasError?.phone && <ErrorMessage>{errors.contacts[index].phone.message}</ErrorMessage>}
              </ErrorWrapper>
            </Col>
            <Col sm={3}>
              <ErrorWrapper error={hasError?.extention}>
                <Label>Ramal</Label>
                <Controller
                  control={control}
                  name={`contacts.${index}.extension`}
                  render={({ field: { onChange, value, ref } }) => (
                    <Input placeholder='00000' maxLength='5' value={value} getInputRef={ref} onChange={onChange} />
                  )}
                />
                {hasError?.extention && <ErrorMessage>{errors.contacts[index].extention.message}</ErrorMessage>}
              </ErrorWrapper>
            </Col>
            <Col sm={3}>
              <ErrorWrapper error={hasError?.type}>
                <Label variant='select'>Tipo de Contato *</Label>
                <Controller
                  control={control}
                  name={`contacts.${index}.type`}
                  render={({ field: { onChange, value, ref } }) => {
                    return (
                      <Select
                        placeholder='Selecione'
                        value={value}
                        inputRef={ref}
                        onChange={(option) => onChange(option.target.value)}
                        options={contactTypes}
                        getOptionValue={(option) => option.code}
                        getOptionLabel={(option) => option.description}
                        isLoading={contactTypes.length === 0}
                        isDisabled={contactTypes.length === 0}
                      />
                    )
                  }}
                />
                {hasError?.type && <ErrorMessage>{errors.contacts[index].type?.code?.message}</ErrorMessage>}
              </ErrorWrapper>
            </Col>
          </Row>
        </React.Fragment>
      )
    })
  }

  function confirmCancel() {
    setCancelModal(false)
    dispatch(companyContactsClear())
    prevTab()
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} id='contactInformation'>
        <Fieldset disabled={readOnly}>
          <Container style={{ margin: 0, padding: 0, maxWidth: 'initial' }}>
            {generateContactFields()}
            <Row gutterWidth={40}>
              <Col sm={12}>
                {fields.length < CONTACS_LIMIT && (
                  <AddContact onClick={() => append({ fullName: '' })}>
                    <svg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M0.5625 8.30566C0.5625 3.89941 4.125 0.305664 8.5625 0.305664C12.9688 0.305664 16.5625 3.89941 16.5625 8.30566C16.5625 12.7432 12.9688 16.3057 8.5625 16.3057C4.125 16.3057 0.5625 12.7432 0.5625 8.30566ZM8.5625 11.8057C8.96875 11.8057 9.3125 11.4932 9.3125 11.0557V9.05566H11.3125C11.7188 9.05566 12.0625 8.74316 12.0625 8.30566C12.0625 7.89941 11.7188 7.55566 11.3125 7.55566H9.3125V5.55566C9.3125 5.14941 8.96875 4.80566 8.5625 4.80566C8.125 4.80566 7.8125 5.14941 7.8125 5.55566V7.55566H5.8125C5.375 7.55566 5.0625 7.89941 5.0625 8.30566C5.0625 8.74316 5.375 9.05566 5.8125 9.05566H7.8125V11.0557C7.8125 11.4932 8.125 11.8057 8.5625 11.8057Z'
                        fill='#1976D2'
                      />
                    </svg>
                    Adicionar contato
                  </AddContact>
                )}
              </Col>
            </Row>
          </Container>
        </Fieldset>
      </form>
      <Row justify='end' gutterWidth={24} style={{ marginTop: '8vh' }}>
        <Col sm='content'>
          {mode === FORM_MODE.VIEW ? (
            <Button variant='outlined' onClick={prevTab}>
              Voltar
            </Button>
          ) : (
            <Button variant='outlined' onClick={() => setCancelModal(true)}>
              Voltar
            </Button>
          )}
        </Col>
        <Col sm='content'>
          {mode === FORM_MODE.VIEW ? (
            <Button type='button' onClick={nextTab} variant='contained'>
              Continuar
            </Button>
          ) : (
            <Button type='submit' form='contactInformation' variant='contained'>
              Continuar
            </Button>
          )}
        </Col>
      </Row>
      {!!removeContact && (
        <Modal
          open={!!removeContact}
          message='Deseja mesmo apagar este contato?'
          confirm={() => {
            removeContact()
          }}
          cancel={() => {
            setRemoveContact(null)
          }}
        />
      )}
      {cancelModal && (
        <Modal
          message='Atenção! Os dados preenchidos serão perdidos. Deseja sair da página?'
          open={cancelModal}
          confirm={confirmCancel}
          cancel={() => setCancelModal(false)}
        />
      )}
      {finishForm && (
        <Modal
          open={finishForm}
          confirm={() => {
            sendToApi(true)
          }}
          loading={loading}
          cancel={() => setFinishForm(false)}
        >
          <SendFormConfirmation>
            <p>
              <TextDecoration>Atenção!</TextDecoration>{' '}
            </p>
            <p>
              Após passar para a proxima etapa <br />
              <TextDecoration>não será possível alterar as informações</TextDecoration>.
            </p>
            <p>Deseja continuar?</p>
          </SendFormConfirmation>
        </Modal>
      )}
    </>
  )
}
export default Contacts
