import { validateEmail } from 'validations-br'
import * as yup from 'yup'

export const schema = yup.object().shape({
  username: yup
    .string()
    .required('Nome é obrigatório.')
    .test('is-name', 'Digite um nome completo.', (value) => !!value.split(' ')?.[1]?.length),
  email: yup.string().required('E-mail é obrigatório').email('E-mail inválido!'),
  confirmEmail: yup
    .string()
    .required('E-mail é obrigatório')
    .email('E-mail inválido!')
    .oneOf([yup.ref('email'), null], 'Os emails devem ser iguais'),
})
