import styled from 'styled-components'
import { Select as SelectComponent, Checkbox as CheckboxComponent } from '@stationkim/front-ui'
import { Row as GridRow } from 'react-grid-system'

export const Content = styled.div`
  width: 90%;
`

export const Label = styled.div`
  margin-bottom: ${({ variant }) => (variant ? '0' : '10px')};
  white-space: nowrap;
  font-size: 14px;
  color: rgba(6, 6, 6, 1);
`

export const Row = styled(GridRow)`
  margin-top: 32px;
  &:first-child {
    margin-top: 0;
  }
`

export const Select = styled(SelectComponent)`
  span {
    margin-bottom: 10px;
    white-space: nowrap;
    font-size: 14px !important;
    color: rgba(6, 6, 6, 1);
  }
`

export const FieldSet = styled.fieldset`
  border: none;
`

export const CheckBoxLabel = styled.small`
  font-family: Montserrat;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
`
